import { render, staticRenderFns } from "./SuperProductView.vue?vue&type=template&id=2c2c1f30&scoped=true&"
import script from "./SuperProductView.vue?vue&type=script&lang=js&"
export * from "./SuperProductView.vue?vue&type=script&lang=js&"
import style0 from "./SuperProductView.vue?vue&type=style&index=0&id=2c2c1f30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c2c1f30",
  null
  
)

export default component.exports
<template>
  <LayoutSuperadmin>
    <template v-slot:title>PRODUCTS</template>
    <div class="product-view h-100 overflow-auto p-2 pl-md-5 pr-md-5">
      <!-- Back to list btn -->
      <GoBackBtn :url="{ name: 'ProductList' }" persistText>
        Back to product list
      </GoBackBtn>
      <!-- Product Details -->
      <AppLoading fillSpace v-if="loading"></AppLoading>

      <div v-else class="mt-5 d-flex align-items-center justify-content-center">
        <AppInputForm
          v-model="formValid"
          class="product-add-form col-12 col-md-9"
          @validate="submitProduct"
          v-slot="{ validate }"
        >
          <div class="product-view-card row flex-grow-1">
            <div class="col-12 col-md-6">
              <div class="w-100 position-relative">
                <!-- Media  -->
                <AppMediaViewer
                  aspect-ratio="1/1"
                  ref="mediaFile"
                  :src="displayImage"
                  :play="videoPlaying"
                  @click="videoPlaying = false"
                />
                <!-- video overlay -->
                <div
                  v-if="isVideo(displayImage) && !videoPlaying"
                  class="
                    product-view-card-play
                    w-100
                    h-100
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                  @click="videoPlaying = true"
                >
                  <AppIcon hoverable name="play_filled" size="100"></AppIcon>
                </div>
              </div>
              <!-- Image List -->
              <div
                v-if="product && product.files"
                class="product-view-card-images w-100 d-flex align-items-center"
              >
                <!-- Back btn -->
                <AppIcon
                  v-if="product.files.length < 5 && filePage > 1"
                  hoverable
                  name="chevron_left"
                  class="product-view-card-images-back"
                  @click="filePage -= 1"
                ></AppIcon>
                <!-- Next btn -->
                <AppIcon
                  v-if="
                    product.files.length < 5 &&
                    filePage < product.files.length / 5
                  "
                  hoverable
                  name="chevron_right"
                  class="product-view-card-images-next"
                  @click="filePage += 1"
                ></AppIcon>
                <!-- Images -->
                <div
                  class="product-view-card-image m-1"
                  v-for="(img, index) in product.files.slice(
                    (filePage - 1) * 5,
                    5
                  )"
                  :key="`product-img-${img}-${index}`"
                  @click="setDisplayImage(img.filename)"
                >
                  <AppMediaViewer
                    :src="img.filename"
                    width="100%"
                    style="aspect-ratio: 1/1"
                    class="p-1"
                  />
                </div>
              </div>
            </div>
            <div class="mt-5 mt-md-0 col-12 col-md-6">
              <h2 class="mb-0 text-uppercase">{{ product.name }}</h2>
              <h2 class="text-gray">
                {{displayPrice}}
              </h2>
              <p>{{ product.description }}</p>
              <!-- Variant options -->
              <div>
                <!-- Quantity -->
                <div class="d-flex text-uppercase font-weight-5">
                  <span
                    v-if="product.quantities && product.quantities.length > 0"
                    class="
                      text-uppercase
                      font-weight-5
                      w-100
                      mr-1
                      flex-shrink-1
                    "
                  >
                    Unit
                    <span class="text-danger">*</span>
                  </span>
                  <span
                    class="text-uppercase font-weight-5 w-100 flex-shrink-1"
                  >
                    Quantity
                    <span class="text-danger">*</span>
                  </span>
                </div>
                <div class="d-flex">
                  <AppInputSelect
                    v-model="cartForm.quantity_option"
                    v-if="product.quantities && product.quantities.length > 0"
                    class="w-100 mr-1"
                    :validate="validate"
                    :validations="[validations.required()]"
                    :items="mappedQuantityOptions"
                  ></AppInputSelect>
                  <AppInput
                    v-model="cartForm.quantity"
                    min="1"
                    type="number"
                    class="w-100 ml-1 flex-shrink-1"
                    :validate="validate"
                    :validations="[validations.required()]"
                  ></AppInput>
                </div>
                <div
                  v-if="
                    product.has_variant === 1 && product.variants.length > 0
                  "
                  class="d-flex flex-column"
                >
                  <AppInputSelect
                    v-model="cartForm.product_variant_id"
                    searchable
                    label="Size"
                    :items="mappedVariants"
                    :disabled="isVariantsDisabled"
                    :shouldSelectFirstItem="false"
                    :validate="validate"
                    :validations="[validations.required()]"
                  ></AppInputSelect>
                </div>
                <!-- Notes -->
                <AppInputTextArea
                  v-model="cartForm.note"
                  label="Notes"
                  class="w-100"
                  placeholder="Enter special notes..."
                  style="min-height: 110px !important"
                ></AppInputTextArea>
                <!-- Error Message -->
                <p
                  v-if="innerError"
                  class="m-0 p-0 mb-2 text-center text-danger"
                >
                  {{ innerError }}
                </p>
              </div>
              <!-- Action Button -->
              <div class="row" v-if="product.status === 'live'">
                <div class="col-6">
                  <AppBtn
                    class="pl-1 pr-1 w-100"
                    type="submit"
                    @click="action = 'addProductToQuote'"
                  >
                    ADD TO QUOTE
                  </AppBtn>
                </div>
                <div class="col-6">
                  <AppBtn class="w-100" type="submit" @click="action = 'buyNow'"
                    >ADD TO CART</AppBtn
                  >
                </div>
                <div class="col-12 mt-3">
                  <AppBtn
                      text
                      :color="isFavorite ? 'danger' : 'black' "
                      @click="saveFavorite()"
                      class="w-100"
                      prependIcon="favorite"
                  >
                    {{ isFavorite ? 'UNSAVE PRODUCT' : 'SAVE PRODUCT' }}
                  </AppBtn>
                </div>
              </div>
            </div>
          </div>
        </AppInputForm>
      </div>

      <ProductViewCheckoutModal
        v-model="addToCart"
        :item="addToCartItem"
      ></ProductViewCheckoutModal>

      <div v-if="false" class="product-view-divider m-5 ml-auto mr-auto">
        <AppDivider size="2"></AppDivider>
      </div>
      <!-- Related Products -->
      <div v-if="false" class="product-view-related-products m-auto">
        <h4>RELATED PRODUCTS</h4>
        <div class="row">
          <div
            v-for="(product, index) in relatedProducts"
            :key="`related-products-${index}`"
            class="col-6 col-md-3"
          >
            <ProductListCard
              :name="product.name"
              :price="product.price"
              :thumbnail="product.thumbnail"
              :stocks="getProductStocks(product)"
              class="mb-4 mb-lg-0"
            ></ProductListCard>
          </div>
        </div>
      </div>
    </div>
  </LayoutSuperadmin>
</template>

<script>
import ProductListCard from '@/components/Partial/Products/ProductListCard.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppDivider from '@/shared/elements/AppDivider.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppInputSelect from '@/shared/elements/AppInputSelect.vue';
import AppInputTextArea from '@/shared/elements/AppInputTextArea.vue';
import Products from '@/shared/api/Products';
import Favorites from '@/shared/api/Favorites';
import AppInput from '@/shared/elements/AppInput.vue';
import fileExtensionChecker from '@/shared/utils/fileExtensionChecker';
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppLoading from '@/shared/elements/AppLoading.vue';
import { required } from '@/shared/utils/validation';
import ProductViewCheckoutModal from '@/components/Buyer/ProductView/ProductViewCheckoutModal.vue';
import LayoutSuperadmin from '../../../../components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
import AppInputForm from '@/shared/elements/AppInputForm.vue';
import CartItems from '@/shared/api/CartItems';

export default {
  name: 'SuperProductView',

  components: {
    AppInputForm,
    AppIcon,
    AppInputSelect,
    AppInputTextArea,
    AppBtn,
    AppDivider,
    ProductListCard,
    AppInput,
    GoBackBtn,
    AppMediaViewer,
    AppLoading,
    ProductViewCheckoutModal,
    LayoutSuperadmin,
  },

  data() {
    return {
      displayPrice: 0,
      validations: { required },
      isFavorite: false,
      formValid: false,
      innerError: '',
      addToCart: false,
      addToCartItem: null,
      action: '',
      cartForm: {
        id: -1,
        thumbnail: '',
        product_id: '',
        product_variant_id: -1,
        product_name: '',
        quantity: 1,
        quantity_option: -1,
        type: 'Materials & Supplies',
        note: '',
      },
      loading: true,
      displayImage: '',
      videoPlaying: false,
      filePage: 1,
      product: {},
      relatedProducts: [
        {
          thumbnail: require('@/assets/mock_assets/product/lg-exhaust-pipe.png'),
          name: 'Product-name-1',
          price: '$ 12.00 - $ 20.00',
          stocks: 24,
        },
        {
          thumbnail: require('@/assets/mock_assets/product/lg-metal-brackets-set.png'),
          name: 'Product-name-1',
          price: '$ 12.00 - $ 20.00',
          stocks: 0,
        },
        {
          thumbnail: require('@/assets/mock_assets/product/lg-plywood.png'),
          name: 'Product-name-1',
          price: '$ 12.00 - $ 20.00',
          stocks: 1000,
        },
        {
          thumbnail: require('@/assets/mock_assets/product/lg-sahara-cement.png'),
          name: 'Product-name-1',
          price: '$ 12.00 - $ 20.00',
          stocks: 320,
        },
      ],
    };
  },

  watch: {
    addToCart(val) {
      if (val) {
        this.addToCartItem = this.createProductObject();
      }
    },
    $route: {
      handler() {
        this.getProduct();
      },
      deep: true,
    },
    'cartForm.quantity_option': {
      handler() {
        //Clear the variant selection when they change unit types
        //Forces the user to select again
        this.cartForm.product_variant_id = -1;

        if (this.cartForm.quantity_option === -1) {
          this.displayPrice = 'Select a unit/variant';
        } else {
          this.displayPrice = this.computedPrice(this.cartForm.quantity_option);
        }

      },
      deep: true,
    },
    'cartForm.product_variant_id': {
      handler() {
        // change the price display when a variant is selected
        // loop through mappedVariants to see if variant ID matches
        if (this.cartForm.product_variant_id > -1) {
          let variant = this.mappedVariants.filter((item) => {
            return item.value === this.cartForm.product_variant_id;
          })[0]

          if (variant !== undefined) {
            let variantObject = this.product.variants.filter((item) => {
              return item.id === this.cartForm.product_variant_id;
            })[0]
            this.displayPrice = this.computedPrice(variantObject)
          }
        } else {
          this.displayPrice = 'Select a unit/variant';
        }
      },
      deep: true,
    },
  },

  methods: {
    ...fileExtensionChecker,
    async getProduct() {
      const id = this.$route.params.id;
      this.loading = true;
      this.product = (await Products.getProduct(id)).data;


      if (this.product.variants.length > 0) {
        this.displayPrice = 'Select a unit/variant';
      } else if (this.product.quantities.length > 0) {
        this.displayPrice = 'Select a unit/variant';
      } else {
        this.displayPrice = this.computedPrice(this.product);
      }

      this.loading = false;
      const primaryImg = this.product.files.find(
        (img) => img.type === 'primary_image'
      );

      if (this.product.files.length > 0) {
        const thumbnail =
          primaryImg >= 0
            ? primaryImg.filename
            : this.product.files[0].filename;
        this.setDisplayImage(thumbnail);
        this.cartForm.thumbnail = thumbnail;
      } else {
        this.setDisplayImage('');
      }
      this.cartForm.id = id;
      this.cartForm.product_id = this.product.id;
      // this.cartForm.quantity = this.product.stocks;
      this.cartForm.product_name = this.product.name;
    },
    setDisplayImage(path) {
      this.displayImage = path;
    },
    buyNow() {
      const form = this.createProductObject();
      const item = form;
      item.product_variant_id = item.product_variant_id !== -1 ? item.product_variant_id : null;
      item.quantity_option = item.quantity_option !== -1 ? item.quantity_option : null;
      CartItems.postCartItem({
        item: item,
      }).then((response) => {
        form.id = response.data.item.id
        form.project_name = response.data.cart.project.replace(/\s/g, '').length ? response.data.cart.project : ' '
        form.project_id = response.data.cart.id
        this.$store.dispatch('cart/addToCheckout', form);
        this.$router.push({ name: 'OrderCheckout', params: { cart: true }});
      });
    },
    createProductObject() {
      const variant_id = this.cartForm.product_variant_id;

      return {
        ...this.cartForm,
        quantity_id: this.cartForm.quantity_option?.id,
        product_quantity_id: this.cartForm.quantity_option?.id,
        variant_name: this.product?.variants.find(
          (variant) => variant.id === variant_id
        )?.name,
      };
    },

    addProductToQuote() {
      this.addToCart = true;
    },

    submitProduct() {
      if (!this.formValid && this.action !== '') {
        return;
      }

      this[this.action]();
    },

    getProductStocks(item) {
      let stock = parseInt(item.stocks);

      if (item.variants && item.variants.length > 0) {
        stock += item.variants.reduce(
          (sum, variant) => sum + variant.stocks,
          0
        );
      }

      return stock;
    },

    async getFavorite() {
      const favorite = (await Favorites.getFavorite(this.$route.params.id)).data;
      this.isFavorite = favorite?.id !== undefined;
    },

    async saveFavorite() {
      if (!this.isFavorite) {
        await Favorites.postFavorite({
          product_id: this.$route.params.id
        });
        this.isFavorite = true;
      } else {
        await Favorites.deleteFavorite(this.$route.params.id);
        this.isFavorite = false;
      }
    },
    computedPrice(variant) {
      // if price_fixed is ineligible then pick the range
      let priceFixed = parseInt(variant.price_fixed);
      let priceStart = parseInt(variant.price_start);

      if (priceFixed < 0 || isNaN(priceFixed)) {
        return '$' + variant.price_start + ' - $' + variant.price_end;
      }

      if (priceStart > 0) {
        return '$' + variant.price_start + ' - $' + variant.price_end;
      }

      return '$' + variant.price_fixed;
    },
  },

  computed: {
    mappedVariants() {
      let variants = this.product.variants;

      const hasChosenUnit = this.cartForm.quantity_option !== -1;
      const chosenUnit = this.mappedQuantityOptions.find(
        (qty) => qty.name === this.cartForm.quantity_option?.quantity?.name
      );

      if (variants.length > 0 && hasChosenUnit && chosenUnit) {
        variants = variants.filter(
          (variant) => variant.quantity_option === chosenUnit.name
        );
      }

      variants = [
        {
          text: 'Select Variant',
          name: '',
          value: -1,
        },
        ...variants.map((variant) => ({
          text: variant.name,
          value: variant.id,
        })),
      ];

      return variants;
    },
    mappedQuantityOptions() {
      return [
        {
          text: 'Select Unit',
          name: '',
          value: -1,
        },
        ...this.product.quantities.map((qty) => ({
          text: `${qty.quantity.name} (Piece/Unit: ${qty.conversion})`,
          name: qty.quantity.name,
          value: qty,
        })),
      ];
    },
    isVariantsDisabled() {
      //Only check for variants if it has no quantity
      if (this.product.quantities.length <= 0) {
        return false;
      }

      //Check that they have selected a quantity option first before variant
      return this.cartForm.quantity_option === -1;
    },
  },

  mounted() {
    this.getProduct();
    this.getFavorite();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';
@import '@/scss/theme/_border';

.product-view {
  &-back-btn {
    cursor: pointer;
  }
  &-divider {
    max-width: 1000px;
  }
  &-card,
  &-related-products {
    max-width: 1000px;
  }
  &-card {
    img,
    video {
      aspect-ratio: 1/1;
      width: 100%;
    }
    video {
      cursor: pointer;
    }
    &-play {
      position: absolute;
      margin: auto;
      top: 0;
    }
    &-images {
      &-back {
        position: absolute;
        left: 0;
      }
      &-next {
        position: absolute;
        right: 0;
      }
    }

    &-image {
      width: 20%;

      &:hover {
        @extend .border;
        @extend .border-1;
        @extend .border-primary;
      }
    }
  }
}
</style>
